


import React from "react"
import Account from "../../components/Account"
import Layout from "../../components/Layout"
import ProtectedPage from "../../components/common/ProtectedPage"


const AccountPage = () => (
  <Layout contentStyleProps={{paddingLeft: "0px", paddingRight: "0px"}} seoProps={{title: "Your Account"}}>
    <ProtectedPage>
        <Account  />
    </ProtectedPage>
  </Layout>
)

export default AccountPage
